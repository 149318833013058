var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposInputWrapper", {
    attrs: {
      field: _vm.field,
      error: _vm.effectiveError,
      uid: _vm.uid,
      items: _vm.next,
      "display-options": _vm.displayOptions,
    },
    scopedSlots: _vm._u([
      {
        key: "additional",
        fn: function () {
          return [
            _c("AposMinMaxCount", {
              attrs: { field: _vm.field, value: _vm.next },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _vm.field.inline
              ? _c(
                  "div",
                  [
                    !_vm.items.length && _vm.field.whenEmpty
                      ? _c(
                          "div",
                          { staticClass: "apos-input-array-inline-empty" },
                          [
                            _vm.field.whenEmpty.icon
                              ? _c(_vm.field.whenEmpty.icon, {
                                  tag: "component",
                                  attrs: { size: 50 },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.field.whenEmpty.label
                              ? _c(
                                  "label",
                                  {
                                    staticClass:
                                      "apos-input-array-inline-empty-label",
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(
                                          _vm.$t(_vm.field.whenEmpty.label)
                                        ) +
                                        "\n        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.items.length
                      ? _c(
                          _vm.field.style === "table" ? "table" : "div",
                          {
                            tag: "component",
                            class:
                              _vm.field.style === "table"
                                ? "apos-input-array-inline-table"
                                : "apos-input-array-inline-standard",
                          },
                          [
                            _vm.field.style === "table"
                              ? _c(
                                  "thead",
                                  [
                                    _c("th", {
                                      staticClass: "apos-table-cell--hidden",
                                    }),
                                    _vm._v(" "),
                                    _vm._l(_vm.field.schema, function (schema) {
                                      return _c("th", { key: schema._id }, [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.$t(schema.label)) +
                                            "\n          "
                                        ),
                                      ])
                                    }),
                                    _vm._v(" "),
                                    _c("th"),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "draggable",
                              _vm._b(
                                {
                                  staticClass: "apos-input-array-inline",
                                  attrs: {
                                    tag:
                                      _vm.field.style === "table"
                                        ? "tbody"
                                        : "div",
                                    role: "list",
                                    list: _vm.items,
                                    id: _vm.listId,
                                  },
                                },
                                "draggable",
                                _vm.dragOptions,
                                false
                              ),
                              _vm._l(_vm.items, function (item, index) {
                                return _c("AposSchema", {
                                  key: item._id,
                                  staticClass: "apos-input-array-inline-item",
                                  class:
                                    item.open && !_vm.alwaysExpand
                                      ? "apos-input-array-inline-item--active"
                                      : null,
                                  attrs: {
                                    schema: _vm.field.schema,
                                    "trigger-validation": _vm.triggerValidation,
                                    "utility-rail": false,
                                    generation: _vm.generation,
                                    modifiers: ["small", "inverted"],
                                    "doc-id": _vm.docId,
                                    "following-values":
                                      _vm.getFollowingValues(item),
                                    "field-style": _vm.field.style,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "before",
                                        fn: function () {
                                          return [
                                            _c(
                                              _vm.field.style === "table"
                                                ? "td"
                                                : "div",
                                              {
                                                tag: "component",
                                                staticClass:
                                                  "apos-input-array-inline-item-controls",
                                              },
                                              [
                                                _vm.field.draggable
                                                  ? _c("AposIndicator", {
                                                      staticClass:
                                                        "apos-drag-handle",
                                                      attrs: {
                                                        icon: "drag-icon",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.field.style !== "table" &&
                                                item.open &&
                                                !_vm.alwaysExpand
                                                  ? _c("AposButton", {
                                                      staticClass:
                                                        "apos-input-array-inline-collapse",
                                                      attrs: {
                                                        "icon-size": 15,
                                                        label:
                                                          "apostrophe:close",
                                                        icon: "unfold-less-horizontal-icon",
                                                        type: "subtle",
                                                        modifiers: [
                                                          "inline",
                                                          "no-motion",
                                                        ],
                                                        "icon-only": true,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.closeInlineItem(
                                                            item._id
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.field.style !== "table" &&
                                            !item.open &&
                                            !_vm.alwaysExpand
                                              ? _c(
                                                  "h3",
                                                  {
                                                    staticClass:
                                                      "apos-input-array-inline-label",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openInlineItem(
                                                          item._id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm.getLabel(
                                                            item._id,
                                                            index
                                                          )
                                                        ) +
                                                        "\n              "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "after",
                                        fn: function () {
                                          return [
                                            _c(
                                              _vm.field.style === "table"
                                                ? "td"
                                                : "div",
                                              {
                                                tag: "component",
                                                staticClass:
                                                  "apos-input-array-inline-item-controls--remove",
                                              },
                                              [
                                                _c("AposButton", {
                                                  attrs: {
                                                    label:
                                                      "apostrophe:removeItem",
                                                    icon: "trash-can-outline-icon",
                                                    type: "subtle",
                                                    modifiers: [
                                                      "inline",
                                                      "danger",
                                                      "no-motion",
                                                    ],
                                                    "icon-only": true,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.remove(
                                                        item._id
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: item.schemaInput,
                                    callback: function ($$v) {
                                      _vm.$set(item, "schemaInput", $$v)
                                    },
                                    expression: "item.schemaInput",
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("AposButton", {
                      attrs: {
                        type: "primary",
                        label: _vm.itemLabel,
                        icon: "plus-icon",
                        disabled: _vm.disableAdd(),
                        modifiers: ["block"],
                      },
                      on: { click: _vm.add },
                    }),
                  ],
                  1
                )
              : _c("div", { staticClass: "apos-input-array" }, [
                  _c(
                    "label",
                    { staticClass: "apos-input-wrapper" },
                    [
                      _c("AposButton", {
                        attrs: {
                          label: _vm.editLabel,
                          disabled: _vm.field.readOnly,
                          tooltip: _vm.tooltip,
                        },
                        on: { click: _vm.edit },
                      }),
                    ],
                    1
                  ),
                ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }