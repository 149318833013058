var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.active
    ? _c(
        "div",
        {
          directives: [
            {
              name: "click-outside-element",
              rawName: "v-click-outside-element",
              value: _vm.close,
              expression: "close",
            },
          ],
          staticClass: "apos-popover apos-image-control__dialog",
          class: {
            "apos-is-triggered": _vm.active,
            "apos-has-selection": true,
          },
          attrs: { "x-placement": "bottom" },
        },
        [
          _c(
            "AposContextMenuDialog",
            { attrs: { "menu-placement": "bottom-start" } },
            [
              _c("AposSchema", {
                key: _vm.lastSelectionTime,
                attrs: {
                  schema: _vm.schema,
                  "trigger-validation": _vm.triggerValidation,
                  "utility-rail": false,
                  modifiers: _vm.formModifiers,
                  generation: _vm.generation,
                  "following-values": _vm.followingValues(),
                  "conditional-fields": _vm.conditionalFields(),
                },
                model: {
                  value: _vm.docFields,
                  callback: function ($$v) {
                    _vm.docFields = $$v
                  },
                  expression: "docFields",
                },
              }),
              _vm._v(" "),
              _c(
                "footer",
                { staticClass: "apos-image-control__footer" },
                [
                  _c("AposButton", {
                    attrs: {
                      type: "default",
                      label: "apostrophe:cancel",
                      modifiers: _vm.formModifiers,
                    },
                    on: { click: _vm.close },
                  }),
                  _vm._v(" "),
                  _c("AposButton", {
                    attrs: {
                      type: "primary",
                      label: "apostrophe:save",
                      modifiers: _vm.formModifiers,
                    },
                    on: { click: _vm.save },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }