var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "apos-primary-scrollbar apos-input-wrapper",
      class: { "apos-input-wrapper--disabled": _vm.field.readOnly },
      attrs: { "aria-haspopup": "menu" },
    },
    [
      _c(
        "ul",
        {
          directives: [
            {
              name: "click-outside-element",
              rawName: "v-click-outside-element",
              value: _vm.closeList,
              expression: "closeList",
            },
          ],
          ref: "select",
          staticClass: "apos-input-wrapper apos-combo__select",
          attrs: {
            role: "button",
            "aria-expanded": _vm.showedList.toString(),
            "aria-controls": `${_vm.field._id}-combo`,
            tabindex: _vm.field.readOnly ? null : 0,
          },
          on: {
            click: _vm.toggleList,
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ])
                )
                  return null
                $event.preventDefault()
                return _vm.toggleList.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp",
                  ])
                )
                  return null
                $event.preventDefault()
                return _vm.toggleList.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                )
                  return null
                $event.preventDefault()
                return _vm.toggleList.apply(null, arguments)
              },
            ],
          },
        },
        _vm._l(_vm.selectedItems, function (checked) {
          return _c(
            "li",
            {
              key: checked,
              staticClass: "apos-combo__selected",
              on: {
                click: function ($event) {
                  _vm.selectOption(_vm.getSelectedOption(checked))
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.getSelectedOption(checked)?.label) +
                  "\n      "
              ),
              _c("AposIndicator", {
                staticClass: "apos-combo__close-icon",
                attrs: { icon: "close-icon", "icon-size": 10 },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("AposIndicator", {
        staticClass: "apos-input-icon",
        attrs: { icon: "menu-down-icon", "icon-size": 20 },
      }),
      _vm._v(" "),
      _c(
        "ul",
        {
          ref: "list",
          staticClass: "apos-combo__list",
          class: { "apos-combo__list--showed": _vm.showedList },
          style: { top: _vm.boxHeight + "px" },
          attrs: { id: `${_vm.field._id}-combo`, role: "menu", tabindex: "0" },
          on: {
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ])
                )
                  return null
                $event.preventDefault()
                return _vm.selectOption(_vm.options[_vm.focusedItemIndex])
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
                return _vm.selectOption(_vm.options[_vm.focusedItemIndex])
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k(
                    $event.keyCode,
                    "arrow-down",
                    undefined,
                    $event.key,
                    undefined
                  )
                )
                  return null
                $event.preventDefault()
                return _vm.focusListItem()
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k(
                    $event.keyCode,
                    "arrow-up",
                    undefined,
                    $event.key,
                    undefined
                  )
                )
                  return null
                $event.preventDefault()
                return _vm.focusListItem(true)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                    "Backspace",
                    "Delete",
                    "Del",
                  ])
                )
                  return null
                $event.preventDefault()
                return _vm.closeList(null, true)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape",
                  ])
                )
                  return null
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.closeList(null, true)
              },
            ],
            blur: function ($event) {
              return _vm.closeList()
            },
          },
        },
        _vm._l(_vm.options, function (choice, i) {
          return _c(
            "li",
            {
              key: choice.value,
              staticClass: "apos-combo__list-item",
              class: { focused: _vm.focusedItemIndex === i },
              attrs: { role: "menuitemcheckbox" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.selectOption(choice)
                },
                mouseover: function ($event) {
                  _vm.focusedItemIndex = i
                },
              },
            },
            [
              _vm.isSelected(choice)
                ? _c("AposIndicator", {
                    staticClass: "apos-combo__check-icon",
                    attrs: { icon: "check-bold-icon", "icon-size": 10 },
                  })
                : _vm._e(),
              _vm._v("\n      " + _vm._s(choice.label) + "\n    "),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }