var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.editor
        ? _c(
            "bubble-menu",
            {
              staticClass: "bubble-menu",
              attrs: {
                "tippy-options": { duration: 100, zIndex: 2000 },
                editor: _vm.editor,
              },
            },
            [
              _c(
                "AposContextMenuDialog",
                {
                  attrs: {
                    "menu-placement": "top",
                    "class-list": "apos-rich-text-toolbar",
                    "has-tip": false,
                    modifiers: ["unpadded"],
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "apos-rich-text-toolbar__inner" },
                    _vm._l(_vm.toolbar, function (item, index) {
                      return _c(
                        (_vm.tools[item] && _vm.tools[item].component) ||
                          "AposTiptapUndefined",
                        {
                          key: item + "-" + index,
                          tag: "component",
                          attrs: {
                            name: item,
                            tool: _vm.tools[item],
                            options: _vm.editorOptions,
                            editor: _vm.editor,
                          },
                        }
                      )
                    }),
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.editor
        ? _c(
            "floating-menu",
            {
              staticClass: "apos-rich-text-insert-menu",
              attrs: {
                "should-show": _vm.showFloatingMenu,
                editor: _vm.editor,
                "tippy-options": { duration: 100, zIndex: 2000 },
              },
            },
            [
              _c("div", { staticClass: "apos-rich-text-insert-menu-heading" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("apostrophe:richTextInsertMenuHeading")) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.insert, function (item, index) {
                return _c(
                  "div",
                  {
                    key: `${item}-${index}`,
                    staticClass: "apos-rich-text-insert-menu-item",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "apos-rich-text-insert-menu-icon" },
                      [
                        _c("AposIndicator", {
                          staticClass: "apos-button__icon",
                          attrs: {
                            icon: _vm.insertMenu[item].icon,
                            "icon-size": 35,
                            "fill-color": "currentColor",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.activateInsertMenuItem(
                                item,
                                _vm.insertMenu[item]
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        item === _vm.activeInsertMenuComponent?.name
                          ? _c(_vm.activeInsertMenuComponent.component, {
                              tag: "component",
                              attrs: {
                                active: true,
                                editor: _vm.editor,
                                options: _vm.editorOptions,
                              },
                              on: {
                                "before-commands": _vm.removeSlash,
                                close: _vm.closeInsertMenuItem,
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return (($event) => null).apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "apos-rich-text-insert-menu-label",
                        on: {
                          click: function ($event) {
                            return _vm.activateInsertMenuItem(
                              item,
                              _vm.insertMenu[item]
                            )
                          },
                        },
                      },
                      [
                        _c("h4", [
                          _vm._v(_vm._s(_vm.$t(_vm.insertMenu[item].label))),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$t(_vm.insertMenu[item].description))
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "apos-rich-text-editor__editor",
          class: _vm.editorModifiers,
        },
        [
          _c("editor-content", {
            class: _vm.editorOptions.className,
            attrs: { editor: _vm.editor },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showPlaceholder !== null && (!_vm.placeholderText || !_vm.isFocused)
        ? _c(
            "div",
            {
              staticClass: "apos-rich-text-editor__editor_after",
              class: _vm.editorModifiers,
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("apostrophe:emptyRichTextWidget")) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }