var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apos-image-control" },
    [
      _c("AposButton", {
        class: { "apos-is-active": _vm.buttonActive },
        attrs: {
          type: "rich-text",
          label: _vm.tool.label,
          "icon-only": !!_vm.tool.icon,
          icon: _vm.tool.icon || false,
          modifiers: ["no-border", "no-motion"],
        },
        on: { click: _vm.click, close: _vm.close },
      }),
      _vm._v(" "),
      _c("AposImageControlDialog", {
        attrs: { active: _vm.active, editor: _vm.editor },
        on: {
          close: _vm.close,
          click: function ($event) {
            $event.stopPropagation()
            return (($event) => null).apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }